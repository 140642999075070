<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="tests"
    :server-items-length="pagination.total"
    :loading="loading"
    @pagination="handlePagination"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Тесты</VToolbarTitle>

        <VSpacer />

        <VDialog
          v-model="confirmDialog"
          max-width="500px"
          @click:outside="handleCancel"
        >
          <VCard>
            <VCardTitle class="headline">
              Удалить тест
            </VCardTitle>

            <VCardText>
              Тест <span class="red--text">{{ test.name }}</span> будет удален! <br>
              Действительно хотите удалить тест?
            </VCardText>

            <VCardActions>
              <VSpacer />

              <VBtn
                text
                @click="handleCancel"
              >
                Нет
              </VBtn>

              <VBtn
                color="red darken-1"
                text
                @click="handleDelete"
              >
                Да
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.status="{ item }">
      <VIcon
        small
        :color="isActiveStatus(item.status) ? 'success' : 'warning'"
      >
        {{ isActiveStatus(item.status) ? 'fal fa-check-circle' : 'fal fa-times-circle' }}
      </VIcon>
    </template>

    <template #item.type="{ item }">
      <strong>{{ typesVal[item.type] }}</strong>
    </template>

    <template #item.action="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_TALENT_TEST, params : { testId : item.id }}"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_TALENT_TEST_TOPICS, params : { testId : item.id }}"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-list
            </VIcon>
          </VBtn>
        </template>

        <span>Топики</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{ name : Names.R_TALENT_TEST_EDIT, params : { testId : item.id }}"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click="deleteTest(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'TestListTable',
  inject: ['Names'],
  data() {
    return {
      tests: [],
      pagination: {
        total: 0,
      },

      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Уровень',
          align: 'left',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Статус',
          align: 'left',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Действия',
          align: 'right',
          sortable: false,
          value: 'action',
        },
      ],
      typesVal: {
        'Test::Eight': 'Многоуровневый',
        'Test::Binary': 'Базовый',
      },
      loading: false,
      test: {},
      confirmDialog: false,
    };
  },
  methods: {
    isActiveStatus(status) {
      return status === 'active';
    },
    async handlePagination({ itemsLength, itemsPerPage, page }) {
      this.loading = true;

      const perPage = itemsPerPage === -1 ? itemsLength : itemsPerPage;
      const offset = page > 0 ? (page - 1) * perPage : 0;

      try {
        const response = await this.$di.api.Talent.testsGet({ params: { per_page: perPage, offset } });

        this.tests = response.tests;
        this.pagination = {
          total: response.pagination.total,
        };
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;
        const { id } = this.test;

        await this.$di.api.Talent.testRemove({ id });
        this.tests = this.tests.filter((test) => test.id !== id);
        this.$di.notify.snackSuccess('Тест удалён');

        this.resetTestEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    resetTestEdit() {
      this.confirmDialog = false;
      this.test = {};
    },

    handleCancel() {
      this.resetTestEdit();
    },

    deleteTest(item) {
      this.confirmDialog = true;
      this.test = { ...item };
    },
  },
};
</script>
