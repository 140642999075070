var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tests,"server-items-length":_vm.pagination.total,"loading":_vm.loading},on:{"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VToolbar',{attrs:{"flat":""}},[_c('VToolbarTitle',[_vm._v("Тесты")]),_c('VSpacer'),_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить тест ")]),_c('VCardText',[_vm._v(" Тест "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.test.name))]),_vm._v(" будет удален! "),_c('br'),_vm._v(" Действительно хотите удалить тест? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1),_vm._t("topActions")],2)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('VIcon',{attrs:{"small":"","color":_vm.isActiveStatus(item.status) ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(_vm.isActiveStatus(item.status) ? 'fal fa-check-circle' : 'fal fa-times-circle')+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.typesVal[item.type]))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"blue","to":{ name : _vm.Names.R_TALENT_TEST, params : { testId : item.id }}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"blue","to":{ name : _vm.Names.R_TALENT_TEST_TOPICS, params : { testId : item.id }}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-list ")])],1)]}}],null,true)},[_c('span',[_vm._v("Топики")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"orange","to":{ name : _vm.Names.R_TALENT_TEST_EDIT, params : { testId : item.id }}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteTest(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }