<template>
  <TTView>
    <VRow>
      <VCol>
        <TestListTable>
          <template #topActions>
            <VBtn
              color="primary"
              depressed
              :to="{
                name : Names.R_TALENT_TEST_CREATE
              }"
            >
              <VIcon left>
                fal fa-plus
              </VIcon>

              Создать
            </VBtn>
          </template>
        </TestListTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TestListTable from '../../../components/talent/test/TestListTable.vue';

export default {
  name: 'TestList',
  components: { TestListTable },
  inject: ['Names'],
};
</script>
